import { RecordStatus } from "../js/enums";
import lookups from "./lookups";
export const InvoiceFormStages = [
  {
    title: "Invoice",
    sections: [
      {
        name: "",
        fields: [
          {
            name: "totalamount",
            className: "col-md-4",
            label: "Total amount",
            type: "money",
            disabled: true,
          },
          {
            name: "statecode",
            className: "col-md-4",
            label: "Status Reason",
            type: "picklist",
            enum: RecordStatus
          },         
          
        ]
      },
      {
        name: "",
        fields: [
          {
            name: "invoicenumber",
            className: "col-md-4",
            description: "",
            label: "InvoiceNumber",
            type: "nvarchar",
            disabled: true,
          },
          {
            name: "name",
            className: "col-md-4",
            label: "Name",
            type: "nvarchar",
          },
          {
            name: "g4b_operatorgroupid",
            className: "col-md-4",
            description: "",
            label: "Operator Group",
            lookup: lookups.g4b_operatorgroup.all,
            type: "lookup",
          },    
        ],
      },
      {
        name:"Sales Information",
        fields:[          
          {
            name: "name",
            className: "col-md-4",
            label: "Name",
            type: "nvarchar",
          },
        ]
      },      
    ],
  },
];
