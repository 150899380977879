import { InvoiceFormStages } from "./invoice";
import lookups from "./lookups";

export const InvoiceBatchHeaderFormStages = [
  {
    title: "Invoice Batch Header",
    sections: [
      {
        name: "",
        fields: [
          {
            name: "g4b_name",
            description: "",
            isNameField: true,
            label: "Name",
            required: true,
            type: "nvarchar",
          },
          {
            name: "g4b_fromdate",
            description: "",
            label: "From date",
            type: "datetime",
          },
          {
            name: "g4b_todate",
            description: "",
            label: "To date",
            type: "datetime",
          },
          {
            name: "g4b_invoicecount",
            description: "",
            label: "Invoice count",
            type: "nvarchar",
          },
        ],
      },
      {
        name: "Invoices",
        table: {
          allowEdit: true,
          fields: [
            {
              name: "totalamount",
              className: "col-number",
              type: "money",
              label:"Total amount"
            },
          ],
          filterByStatus: true,          
          linkedEntityId: "invoiceid",
          loadStateRelatedEntityName: "invoice",
          lookup: lookups.invoice.all,
          subFormStages: InvoiceFormStages,
          useSubForm: false,
          linkForNewRecord: "/invoice",
        },
      },
    ],
  },
];
