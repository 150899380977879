import { InvoiceBatchHeaderFormStages } from "./invoiceBatchHeader";
import lookups from "./lookups";
import { PastYearsSelectHeader } from "../components/elements/DateSelectHeader";

export const InvoiceBatchHeadersFormStages = [
  {
    title: "Invoice Batch Headers",
    sections: [
      {
        name: "",
        table: {
          allowAdd: true,
          allowEdit: true,
          allowStatusEdit: true,
          fields: [
            {
              name: "g4b_name",
              className: "col-name",
              type: "string",
            },{
              name: "g4b_fromdate",
              className: "col-date",
              type: "datetime",
            },
            {
              name: "g4b_todate",
              className: "col-date",
              type: "datetime",
            },
            {
              name: "g4b_invoicecount",
              className: "col-number",
              type: "string",
            },
            {
              name: "createdon",
              className: "col-date",
              type: "datetime",
            },
            {
              name: "modifiedon",
              className: "col-date",
              type: "datetime",
            },
          ],
          filterByStatus: true,
          filters: [
            {
              name: "createdon",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
            {
              name: "modifiedon",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
          ],
          linkForNewRecord: "/invoice-batch-header",
          lookup: lookups.g4b_invoicebatchheader.all,
          subFormStages: InvoiceBatchHeaderFormStages,
          useAlphabetPagination: true,
          useSubForm: false,
        },
      },
    ],
  },
];
