import { InvoiceFormStages } from "./invoice";
import lookups from "./lookups";
import { CommissionGroupFormStages } from "./commissionGroup";

export const OperatorGroupFormStages = [
  {
    title: "Operator Group",
    sections: [
      {
        name: "",
        fields: [
          {
            name: "g4b_name",
            description: "",
            isNameField: true,
            label: "Name",
            required: true,
            type: "nvarchar",
          },
          {
            name: "g4b_isallowedtotaketokenisedpayments",
            default: false,
            description: "",
            label: "Allow tokenised payments",
            type: "bit",
          },
          {
            name: "g4b_accountid",
            className: "col-md-4",
            description: "",
            label: "Account",
            lookup: lookups.account.all,
            type: "lookup",
          },          
          {
            allowAdd: true,
            allowEdit: true,
            name: "g4b_commissiongroupid",
            description: "",
            label: "Commission Group",
            lookup: lookups.g4b_commissiongroup.all,
            subFormStages: CommissionGroupFormStages,
            type: "lookup",
          },
        ],
      },
      {
        name: "Invoices",
        table: {
          allowEdit: true,
          fields: [
            {
              name: "totalamount",
              className: "col-number",
              type: "money",
              label:"Total amount"
            },
          ],
          filterByStatus: true,          
          linkedEntityId: "invoiceid",
          loadStateRelatedEntityName: "invoice",
          lookup: lookups.invoice.all,
          subFormStages: InvoiceFormStages,
          useSubForm: false,
          linkForNewRecord: "/invoice",
        },
      },
    ],
  },
];
