import { Fragment } from "react";
import {
  disableLinkClicks,
  isSameOrLaterDate,
  isValidEmail,
} from "../js/utility";
import lookups from "./lookups";
import { FutureYearsSelectHeader } from "../components/elements/_Elements";
import CommunicationAttributes from "../components/form/communication/CommunicationAttributes";
import CommunicationDesigner from "../components/form/communication/CommunicationDesigner";
import CommunicationStatistics from "../components/form/communication/CommunicationStatistics";
import CommunicationTable from "../components/form/communication/CommunicationTable";
import ReadOnlyAudienceRulesAndCount from "../components/form/communication/ReadOnlyAudienceRulesAndCount";
import { CampaignFormStages } from "./campaign";
import SavedLayouts from "../components/form/communication/SavedLayouts";
import { DesignMode } from "../components/form/communication/enums";
import {
  AudienceType,
  AudienceTypeNoAB,
  CommunicationAudienceType,
  CommunicationPublishedStatus,
  Regarding,
  RegardingContactOnly,
  RegardingForAccount,
  SelectedStartType,
  TargetedAtType,
} from "../js/enums";
import { generateCommunicationTemplateDataAndHTML } from "../components/form/communication/Helpers";
import CommunicationABTestPublishMessage from "../components/form/communication/CommunicationABTestPublishMessage";

export const CommunicationFormStages = [
  {
    title: "Review",
    id: "review",
    displayEditButtonFromState: (values) => values.statuscode !== 4,
    displayIfFromState: (values) =>
      values.g4c_customerid && values.statuscode === 4,
    hideNavigationButtons: (values) => values.statuscode === 4,
    renderStage: false,
    sections: [
      {
        name: "",
        fields: [
          {
            name: "statuscode",
            enum: CommunicationPublishedStatus,
            hidden: true,
            label: "Status",
            type: "picklist",
          },
          {
            name: "g4c_sid",
            hidden: true,
            label: "SID",
            type: "nvarchar",
          },
          {
            name: "templateHtml",
            hidden: true,
          },
          {
            name: "templateData",
            className: "d-none",
            displayIfFromState: (values) =>
              values.g4c_customerid && values.statuscode === 4,
            overviewRender: (state) => {
              // we need to render the Html content, but hidden, so that it is available for copy communication
              const {
                globalBackgroundColour,
                globalContentBackgroundColour,
                globalContentPadding,
                globalLinkColour,
                globalPadding,
                rows,
              } = state;

              return (
                <CommunicationTable
                  allowEdit={false}
                  backgroundColour={globalBackgroundColour}
                  contentBackgroundColour={
                    globalContentBackgroundColour
                  }
                  contentPadding={globalContentPadding}
                  linkColour={globalLinkColour}
                  padding={globalPadding}
                  rows={rows}
                />
              );
            },
          },
          {
            name: "",
            className: "col-12",
            component: CommunicationStatistics,
            displayComponentOnOverview: true,
            type: "component",
          },
        ],
      },
    ],
  },
  {
    title: "Communication",
    id: "communication",
    displayAlert: (values) =>
      values.g4c_customerid
        ? ""
        : "Ecomms not activated. Communication cannot be edited.",
    displayEditButtonFromState: (values) => values.statuscode !== 4,
    sections: [
      {
        name: "General",
        fields: [
          {
            name: "g4c_name",
            className: "col-md-6",
            description: "",
            isNameField: true,
            label: "Name",
            required: true,
            type: "nvarchar",
          },
          {
            name: "g4c_communicationtype",
            className: "col-md-6",
            description: "",
            enum: CommunicationAudienceType,
            label: "Type",
            required: true,
            type: "picklist",
          },
          {
            name: "g4c_campaignid",
            className: "col-12",
            allowAdd: true,
            allowEdit: true,
            description: "",
            label: "Campaign",
            lookup: lookups.campaign.all,
            subFormStages: CampaignFormStages,
            required: true,
            type: "lookup",
          },
          {
            name: "g4c_campaignidname",
            hidden: true,
            type: "nvarchar",
          },
          {
            name: "g4c_description",
            className: "col-12",
            description: "",
            label: "Description",
            required: true,
            textAreaHeight: "100px",
            type: "textarea",
          },
        ],
      },
    ],
  },
  {
    title: "Audience",
    id: "audience",
    displayEditButtonFromState: (values) => values.statuscode !== 4,
    displayIfFromState: (values) => values.g4c_customerid,
    sections: [
      {
        name: "",
        fields: [
          {
            name: "g4c_audiencetype",
            description: "",
            label: "Audience",
            type: "picklist",
            required: true,
            displayAltEnum: (values) => {
              if (values.g4c_communicationtype) {
                return String(values.g4c_communicationtype) ===
                  String(CommunicationAudienceType.Marketing)
                  ? AudienceType
                  : AudienceTypeNoAB;
              }
              return null;
            },
          },
          {
            name: "g4c_listtype",
            description: "",
            disabledIfFromState: (state) =>
              state.id || state.g4c_subject,
            displayIfFromValues: (values) =>
              values.g4c_audiencetype &&
              values.g4c_audiencetype !== AudienceType["A/B Testing"],
            enum: TargetedAtType,
            label: "Targeted At",
            required: true,
            type: "picklist",
          },
          {
            name: "RunAt",
            default: "crm", //Other option is warehouse but focusing on crm for now
            hidden: true,
          },
          {
            name: "g4c_audienceid",
            description: "",
            displayIfFromValues: (values) =>
              String(values.g4c_audiencetype) ===
              String(AudienceType.Existing),
            label: "Audience",
            lookup: lookups.g4c_communicationaudience.all,
            required: true,
            type: "lookup",
          },
          {
            name: "g4c_audienceidname",
            hidden: true,
            type: "nvarchar",
          },
          {
            name: "g4c_regardingtype",
            description: "",
            displayAltEnum: (values) =>
              String(values.g4c_audiencetype) ===
              String(AudienceType.Existing)
                ? RegardingContactOnly
                : String(values.g4c_audiencetype) ===
                    String(AudienceType.Flow) &&
                  String(values.g4c_listtype) ===
                    String(TargetedAtType.Account)
                ? RegardingForAccount
                : Regarding,
            displayIfFromValues: (values) =>
              values.g4c_audiencetype &&
              values.g4c_audiencetype !== AudienceType["A/B Testing"],
            label: "Regarding",
            type: "picklist",
            required: true,
          },
          {
            name: "g4c_regardingrelationship",
            hidden: true,
          },
          {
            name: "",
            className: "col-12",
            component: ReadOnlyAudienceRulesAndCount,
            overviewRender: (state, setFieldValue) => {
              return (
                <ReadOnlyAudienceRulesAndCount
                  setFieldValue={setFieldValue}
                  values={state}
                />
              );
            },
            displayIfFromValues: (values) =>
              String(values.g4c_audiencetype) ===
                String(AudienceType.Existing) &&
              values.g4c_audienceid,
            type: "component",
          },
        ],
      },
    ],
  },
  {
    title: "Setup",
    id: "setup",
    displayEditButtonFromState: (values) => values.statuscode !== 4,
    displayIfFromState: (values) => values.g4c_customerid,
    sections: [
      {
        name: "",
        fields: [
          {
            name: "g4c_subject",
            className: "col-12",
            customActions: [CommunicationAttributes],
            customActionLabels: ["Add Attribute"],
            required: true,
            description: "Enter the subject line that will appear",
            label: "Email Subject Line",
            type: "nvarchar",
          },
          {
            name: "FromName",
            className: "col-md-6",
            required: true,
            description: "The name your email is sent from",
            label: "From Name",
            type: "nvarchar",
          },
          {
            name: "FromAddress",
            className: "col-md-6",
            required: true,
            description: "The email address your email is sent from",
            label: "From Email Address",
            type: "email",
            validate: (email) => {
              const isValid = email ? isValidEmail(email) : false;
              return isValid
                ? ""
                : "Please enter a valid email address";
            },
          },
          {
            name: "ToName",
            className: "col-md-6",
            required: true,
            description:
              "The default name used when the To Name doesn't exist",
            label: "Default Name",
            type: "nvarchar",
          },
          {
            name: "ToNameField",
            default: "firstname",
            hidden: true,
          },
          {
            name: "ToAddressField",
            default: "emailaddress1",
            hidden: true,
          },
          {
            name: "g4c_unsubscribemarketinglistid",
            className: "col-12",
            description: "",
            label: "Unsubscribe Marketing List",
            lookup: lookups.list.all,
            type: "lookup",
          },
          {
            name: "g4c_unsubscribemarketinglistidname",
            hidden: true,
            type: "nvarchar",
          },
          {
            name: "PlainTextContent",
            className: "col-12",
            customActions: [CommunicationAttributes],
            customActionLabels: ["Add Attribute"],
            label: "Plain Text Email Content",
            description:
              "Plain text version of the email, for clients which do not support HTML",
            textAreaHeight: "100px",
            type: "textarea",
          },
          {
            name: "g4c_attributiontracking",
            className: "col-md-6",
            label: "Attribution Tracking",
            description: "",
            type: "bit",
          },
          {
            name: "g4c_usedynamiccontent",
            className: "col-md-6",
            label: "Dynamic Content",
            description:
              "If enabled, this option allows content to be shown dynamically based on field properties",
            type: "bit",
          },
        ],
      },
    ],
  },
  {
    title: "Layout",
    id: "layout",
    displayIfFromState: (values) =>
      values.g4c_customerid && values.statuscode !== 4,
    sections: [
      {
        name: "",
        fields: [
          {
            name: "SelectedTemplate",
            className: "col-12",
            component: SavedLayouts,
            getReadOnlyValue: (state) => {
              if (!state.SelectedTemplate) {
                return "";
              }

              return state.SelectedTemplate.Value;
            },
            required: true,
            lookup: lookups.g4c_communicationsavedlayout.all,
            type: "component",
          },
        ],
      },
    ],
  },
  {
    title: "Design",
    id: "design",
    displayIfFromState: (values) =>
      values.g4c_customerid && values.statuscode !== 4,
    formClass: "design-stage is-fixed",
    sections: [
      {
        name: "",
        fields: [
          {
            name: "templateData",
            className: "w-100 mb-3",
            component: CommunicationDesigner,
            overviewRender: (state) => {
              const {
                globalBackgroundColour,
                globalContentBackgroundColour,
                globalContentPadding,
                globalLinkColour,
                globalPadding,
                rows,
              } = state;

              return (
                <div onClick={(event) => disableLinkClicks(event)}>
                  <CommunicationTable
                    allowEdit={false}
                    backgroundColour={globalBackgroundColour}
                    contentBackgroundColour={
                      globalContentBackgroundColour
                    }
                    contentPadding={globalContentPadding}
                    linkColour={globalLinkColour}
                    padding={globalPadding}
                    rows={rows}
                  />
                </div>
              );
            },
            type: "component",
          },
          {
            name: "designMode",
            autoFillValue: () => {
              return String(DesignMode.Controls);
            },
            hidden: true,
            enum: DesignMode,
            type: "picklist",
          },
          {
            name: "globalBackgroundColour",
            hidden: true,
            type: "nvarchar",
          },
          {
            name: "globalContentBackgroundColour",
            hidden: true,
            type: "nvarchar",
          },
          {
            name: "globalContentPadding",
            hidden: true,
            type: "nvarchar",
          },
          {
            name: "globalLinkColour",
            hidden: true,
            type: "nvarchar",
          },
          {
            name: "globalPadding",
            hidden: true,
            type: "nvarchar",
          },
          {
            name: "rows",
            hidden: true,
            type: "nvarchar",
          },
          {
            name: "templateHtml",
            hidden: true,
            type: "nvarchar",
          },
        ],
        render: (formFields, index) => {
          return <Fragment key={index}>{formFields}</Fragment>;
        },
      },
    ],
    showButtonSection: (values) => !values.editControl,
    updateValuesBeforeSubmit: (state, values) => {
      let commTemplateDataAndHTML =
        generateCommunicationTemplateDataAndHTML(state);
      values.templateData = commTemplateDataAndHTML.templateData;
      values.templateHtml = commTemplateDataAndHTML.templateHTML;
    },
  },
  {
    title: "Test",
    id: "test",
    displayEditButtonFromState: (values) => values.statuscode !== 4,
    displayIfFromState: (values) => values.g4c_customerid,
    editOnOverviewFromState: (values) => values.statuscode !== 4,
    renderStage: false,
    sections: [
      {
        name: "",
        fields: [
          {
            name: "g4c_testaddress",
            className: "col-md-6",
            description: "Email address which tests will be sent to",
            label: "Test Email Address",
            type: "email",
            validate: (email) => {
              return email && !isValidEmail(email)
                ? "Please enter a valid email address"
                : "";
            },
          },
          {
            name: "g4c_testmarketinglistid",
            className: "col-md-6",
            description:
              "Test emails will impersonate the contacts within this marketing list",
            label: "Test Marketing List",
            lookup: lookups.list.all,
            type: "lookup",
          },
          {
            name: "g4c_testmarketinglistidname",
            hidden: true,
            type: "nvarchar",
          },
        ],
      },
    ],
  },
  {
    title: "Publish",
    id: "publish",
    displayEditButtonFromState: (values) => values.statuscode !== 4,
    editOnOverviewFromState: (values) => values.statuscode !== 4,
    renderStage: false,
    sections: [
      {
        name: "",
        fields: [
          {
            name: "g4c_starttype",
            className: "col-12",
            description: "",
            label: "When",
            type: "picklist",
            autoFillValue: (values) => {
              return values != null &&
                String(values.g4c_audiencetype) ===
                  String(AudienceType.Existing)
                ? SelectedStartType.Immediately
                : "";
            },
            enum: SelectedStartType,
            displayIfFromState: (values) => {
              return (
                values &&
                String(values.g4c_audiencetype) ===
                  String(AudienceType.Existing)
              );
            },
            required: true,
          },
          {
            name: "g4c_startdate",
            className: "col-md-6",
            dateFormat: "dd/MM/yyyy HH:mm",
            description: "",
            label: "Start Date",
            required: true,
            showTimeSelect: true,
            type: "datetime",
            displayIfFromValues: (values) => {
              return (
                values &&
                String(values.g4c_starttype) ===
                  String(SelectedStartType.Scheduled)
              );
            },
          },
          {
            name: "g4c_enddate",
            className: "col-md-6",
            dateFormat: "dd/MM/yyyy HH:mm",
            description: "",
            filterDate: (date, values) => {
              return String(values.g4c_starttype) ===
                String(SelectedStartType.Scheduled) &&
                values.g4c_startdate
                ? isSameOrLaterDate(
                    date,
                    new Date(values.g4c_startdate)
                  )
                : String(values.g4c_starttype) ===
                  String(SelectedStartType.Immediately)
                ? isSameOrLaterDate(date, new Date())
                : true;
            },
            label: "End Date",
            type: "datetime",
            showTimeSelect: true,
            renderCustomHeader: FutureYearsSelectHeader,
            required: true,
            validate: (date, values) => {
              const isValid =
                String(values.g4c_starttype) ===
                  String(SelectedStartType.Scheduled) &&
                values.g4c_startdate
                  ? isSameOrLaterDate(date, values.g4c_startdate)
                  : String(values.g4c_starttype) ===
                    String(SelectedStartType.Immediately)
                  ? isSameOrLaterDate(date, new Date())
                  : true;
              return isValid ? "" : "Select a later date";
            },
          },
          {
            name: "g4c_expirypage",
            className: "col-md-6",
            description:
              "Any attempt to access the email after the end date will redirect to the Expiry URL",
            label: "Expiry URL",
            type: "nvarchar",
          },
          {
            name: "",
            className: "col-12",
            component: CommunicationABTestPublishMessage,
            displayIfFromState: (state) =>
              String(state.g4c_audiencetype) ===
                String(AudienceType["A/B Testing"]) &&
              state.statuscode !== 4,
            type: "component",
          },
        ],
      },
    ],
  },
];
